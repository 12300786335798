import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Chart from '../../components/Chart/Chart';
import { MdLocalAtm, MdPayments, MdAccountBalanceWallet, MdSwapHoriz } from 'react-icons/md';
import ProgressBar from '@ramonak/react-progress-bar';
import SETTINGS from '../../setting.json';
import Loading from 'react-loading';

const axios = require('axios').default;

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' VNĐ';
}

// const formatter = new Intl.NumberFormat('en-us', {
//     style: 'currency',
//     currency: 'USD',
// });

function Wallet({ title }) {
    document.title = title;
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState({
        data: [{ money: 0, dongbangtk: 0, roses: 0 }],
        moneyEarn: { moneyEarnPreDay: 0, moneyEarnToday: 0 },
        mission: {
            amount: 0,
            result: 0,
            amountToday: 0,
        },
    });

    const { t } = useTranslation();

    useEffect(() => {
        // checkToken();
        setIsLoading(true);
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);

                let data = response.data;
                if (data.status === 'ok') {
                    setUser(data);
                }
            })
            .catch(function (error) {
                toast.error(t('content.error'), { theme: 'light' });
            });
    }, []);

    return (
        <div>
            <div className="flex flex-col items-center justify-center text-white mt-16">
                <span className="" style={{ fontSize: '3rem', fontWeight: '500' }}>
                    {isLoading ? (
                        <Loading type="spinningBubbles" color="white" height={'20px'} width={'20px'} className="mb-3" />
                    ) : (
                        formatMoney(user?.data[0].money)
                    )}
                </span>
                <span className="text-2xl mt-3">{t('content.wallet.tongTS')}</span>
            </div>

            <Chart />

            <div
                className="main-container bg-[#ffffff] overflow-x-hidden flex flex-col items-center justify-center"
                style={{ borderRadius: '25px' }}
            >
                <div className="flex items-center justify-between w-[100%]">
                    <div className="px-4 py-8 font-medium" style={{ fontSize: '16px' }}>
                        {t('content.wallet.ptTN')}
                    </div>
                    <div style={{ color: 'transparent' }}>1</div>
                </div>

                <div className="mt-4 tours-item flex flex-col items-center justify-start w-[92%] mx-2 rounded-2xl mb-4 p-4">
                    <div className="flex items-center justify-between w-[100%]">
                        <div>
                            <span className="font-medium" style={{ fontSize: '20px' }}>
                                {isLoading ? (
                                    <Loading
                                        type="spinningBubbles"
                                        color="black"
                                        height={'20px'}
                                        width={'20px'}
                                        className="mb-3"
                                    />
                                ) : (
                                    formatMoney(user?.moneyEarn.moneyEarnToday)
                                )}
                            </span>
                            <div className="fs-3 text-[#0194F3]">{t('content.wallet.tnhh')}</div>
                        </div>
                        <div className="w-[40px] h-[40px] flex items-center justify-center bg-[#0194F3] rounded-full">
                            <MdLocalAtm size={24} color="white" />
                        </div>
                    </div>

                    <ProgressBar
                        completed={`${user?.mission?.amountToday}`}
                        maxCompleted={`${user.data[0].amount}`}
                        height="5px"
                        className="mt-8 w-[100%]"
                        customLabel=""
                        isLabelVisible={false}
                        bgColor="#0194F3"
                    ></ProgressBar>
                </div>

                <div className="mt-12 tours-item flex flex-col items-center justify-center w-[92%] mx-2 rounded-2xl mb-4 p-4">
                    <div
                        className="flex items-center justify-between w-100 pb-4"
                        style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}
                    >
                        <span className="fs-3 font-medium">{t('content.wallet.ttctq')}</span>
                        <div style={{ color: 'transparent' }}>1</div>
                    </div>

                    <div className="flex flex-col items-start justify-center w-[100%]">
                        <Link to="/recharge" className="flex items-center justify-center my-4">
                            <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#0194F3] rounded-2xl">
                                <MdPayments size={24} color="white" />
                            </div>
                            <div className="flex flex-col ml-4">
                                <span className="fs-3">{t('content.wallet.napTien')}</span>
                                <span className="fs-4 text-[#0194F3]">{t('content.wallet.nlsd')}</span>
                            </div>
                        </Link>
                        <Link to="/withdraw" className="flex items-center justify-center my-4">
                            <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#0194F3] rounded-2xl">
                                <MdAccountBalanceWallet size={24} color="white" />
                            </div>
                            <div className="flex flex-col ml-4">
                                <span className="fs-3">{t('content.wallet.rutTien')}</span>
                                <span className="fs-4 text-[#0194F3]">{t('content.wallet.rtvtknh')}</span>
                            </div>
                        </Link>
                        <Link to="/my/history-recharge-withdraw" className="flex items-center justify-center my-4">
                            <div className="w-[50px] h-[50px] flex items-center justify-center bg-[#0194F3] rounded-2xl">
                                <MdSwapHoriz size={24} color="white" />
                            </div>
                            <div className="flex flex-col ml-4">
                                <span className="fs-3">{t('content.wallet.lsRutTien')}</span>
                                <span className="fs-4 text-[#0194F3]">{t('content.wallet.xlsRutTien')}</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Wallet;
