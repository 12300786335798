import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import styles from './Login.module.scss';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import SETTINGS from '../../setting.json';
import { useTranslation } from 'react-i18next';
import backIcon from '../../assets/images/backicon.png';

const axios = require('axios').default;

const cx = classNames.bind(styles);

function Register(props) {
    const [Lock1, setLock1] = useState(false);
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [withdrawPw, setWithdrawPw] = useState('');
    const [invite, setInvite] = useState('');
    const [isAgreeTerm, setIsAgreeTerm] = useState(false);
    const location = useLocation();

    const { t } = useTranslation();

    const searchParams = new URLSearchParams(location.search);
    const invitation_code = searchParams.get('invitation_code');

    useEffect(() => {
        if (invitation_code) setInvite(invitation_code);
    }, [invitation_code]);

    document.title = props.title;

    const handleRegister = async (datas) => {
        let { phone, password, withdrawPw, invite, isAgreeTerm, name } = datas;

        let pattern = /^[0-9]*\d$/;
        if (!phone || !password || !withdrawPw || !invite)
            return toast.warn(`${t('content.login.thieuThongTin')}`, { theme: 'light' });
        // if (!pattern.test(username)) return toast.warn(`${t('content.login.saiDinhDang')}`, { theme: 'light' });

        if (password !== repassword) return toast.warn(t('content.register.matKhauXNKCX'), { theme: 'light' });

        if (!isAgreeTerm) return toast.warn(t('content.register.dieuKien'), { theme: 'light' });

        axios
            .post(`${SETTINGS.BASE_URL}/api/webapi/register`, {
                username: phone,
                password: password,
                invite: invite,
                name: name,
                withdrawPw: withdrawPw,
                phone: phone,
            })
            .then(function (response) {
                let status = response.data.status;
                if (status === 'ok') {
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 1500);
                    localStorage.setItem('auth', response.data.auth);
                    return toast.success(t('content.register.dangKyTC'), { theme: 'light' });
                }

                if (status === 'error' && response.data.message === 'Mã giới thiệu không tồn tại !')
                    return toast.error(t('content.register.maGTKTT'), { theme: 'light' });

                if (status === 'error' && response.data.message === 'Tài khoản này đã tồn tại trong hệ thống!')
                    return toast.error(t('content.register.taiKhoanDTT'), { theme: 'light' });

                if (status === 'error' && response.data.message === 'Địa chỉ IP này đã đăng ký quá số lần cho phép!')
                    return toast.error(t('content.register.trungIP'), { theme: 'light' });
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    };

    return (
        <div className="relative">
            <div className="header-account flex items-center justify-between pt-8">
                <Link to="/login">
                    <img src={backIcon} alt="" width={20} />
                </Link>
                <Link className="text-white fs-3" to="/login">
                    {t('content.register.daCoTaiKhoan')}
                </Link>
            </div>
            <div className={`${cx('side-content')} min-[390px]:mt-36`}>
                <h3 className={`${cx('title')}`}>{t('content.register.taoTKM')}</h3>

                <label htmlFor="Label" className="text-xl text-center pb-4 leading-10 text-white">
                    {t('content.register.luuY')}
                </label>
                <div className={cx('form-group')}>
                    <input
                        className={cx('form-input')}
                        onInput={(e) => {
                            setName(e.target.value);
                        }}
                        type="text"
                        name="name"
                        autoComplete="off"
                        maxLength="128"
                        spellCheck="false"
                        placeholder={t('content.register.inputName')}
                    />
                </div>

                <div className={cx('form-group')}>
                    <input
                        className={cx('form-input')}
                        onInput={(e) => {
                            setPhone(e.target.value);
                        }}
                        type="number"
                        name="phoneNumber"
                        placeholder={t('content.register.inputPhone')}
                        autoComplete="off"
                        maxLength="18"
                        spellCheck="false"
                    />
                </div>
                <div className={cx('form-group')}>
                    <input
                        className={cx('form-input')}
                        onInput={(e) => setPassword(e.target.value)}
                        type={Lock1 ? 'text' : 'password'}
                        autoComplete="new-password"
                        maxLength="18"
                        placeholder={t('content.register.inputPw')}
                    />
                </div>
                <div className={cx('form-group')}>
                    <input
                        className={cx('form-input')}
                        onInput={(e) => setRepassword(e.target.value)}
                        type={Lock1 ? 'text' : 'password'}
                        autoComplete="new-password"
                        maxLength="18"
                        placeholder={t('content.register.confirmPw')}
                    />
                </div>
                <div className={cx('form-group')}>
                    <input
                        name="withdrawpw"
                        className={cx('form-input')}
                        onInput={(e) => setWithdrawPw(e.target.value)}
                        type={Lock1 ? 'text' : 'password'}
                        autoComplete=""
                        maxLength="18"
                        placeholder={t('content.register.inputPw1')}
                    />
                </div>
                <div className={cx('form-group')}>
                    <input
                        className={cx('form-input')}
                        onInput={(e) => setInvite(e.target.value)}
                        type="number"
                        autoComplete="codeInvite"
                        maxLength="18"
                        spellCheck="false"
                        defaultValue={`${invite ? invite : ''}`}
                        placeholder={t('content.register.inputCode')}
                    />
                </div>

                <div className="flex items-center justify-start w-[100%] w-[310px]">
                    <label className="switch">
                        <input type="checkbox" checked={isAgreeTerm} onClick={() => setIsAgreeTerm(!isAgreeTerm)} />
                        <span className="slider round"></span>
                    </label>
                    <span className="text-white ml-4 fs-4">{t('content.register.dongYDK')}</span>
                </div>

                <Link to="/term-conditions" className="text-white fs-3 font-semibold w-[100%] w-[310px] mt-4">
                    {t('content.register.DKvaDK')}
                </Link>

                <div
                    className={cx('btn-submit')}
                    style={{
                        // backgroundColor: '#ec0022',
                        borderRadius: '20px',
                        marginTop: '15px',
                    }}
                >
                    <button
                        type="button"
                        style={{ color: 'white', fontSize: '16px' }}
                        className={cx('block-events')}
                        onClick={() => handleRegister({ name, phone, password, withdrawPw, invite, isAgreeTerm })}
                    >
                        {t('content.login.dangKy')}
                    </button>
                </div>

                {/* <Link className="text-white text-2xl font-bold" to="https://t.me/cskhtraveloka6868" target="_blank">
                    {t('content.register.lienHeCSKH')}
                </Link> */}
            </div>

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Register;
