import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import GlobalStyle from './components/GlobalStyle';
import './index.css';
import './assets/css/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { I18nextProvider } from 'react-i18next';
import i18n from './components/i18n/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <GlobalStyle>
                <App />
            </GlobalStyle>
        </I18nextProvider>
    </React.StrictMode>,
);
