import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import backIcon from '../../assets/images/backicon.png';
import descriptionIcon from '../../assets/images/description.png';
import { Link, useNavigate } from 'react-router-dom';

function CauHoiThuongGap(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const { t } = useTranslation();

    return (
        <div className="withdraw px-[15px] py-[20px] min-h-[920px] bg-login h-[auto]">
            <div className="bg-[#fff] text-2xl rounded-2xl py-4 px-4 leading-10 mt-8">
                <div className="flex items-center justify-start pb-2">
                    <img src={descriptionIcon} alt="" className="image-desc-term" />
                    <span className="ml-4 font-semibold">{t('content.cauHoiTG.cauHoiTG')}</span>
                </div>
                <hr />
                <br />
                <span>{t('content.cauHoiTG.luuY')}</span>
                <br />
                <br />
                <span>• {t('content.cauHoiTG.hanhTrinh')}</span>
                <br />
                <br />
                <span>• {t('content.cauHoiTG.nenTang')}</span>
                <br />
                <br />
                <span>{t('content.cauHoiTG.FQA')}</span>
                <br />
                <br />
                <span>• {t('content.cauHoiTG.tienDo')}</span>
                <br />
                <br />
                <span>1. {t('content.cauHoiTG.xepHang')}</span>
                <br />
                <br />
                <span>• {t('content.cauHoiTG.sauKhi')}</span>
                <br />
                <br />
                <span>2. {t('content.cauHoiTG.rutTien')}</span>
                <br />
                <br />
                <span>{t('content.cauHoiTG.truocKhi')}</span>
                <br />
                <br />
                <span>3. {t('content.cauHoiTG.mangLK')}</span>
                <br />
                <br />
                <span>• {t('content.cauHoiTG.nguoiDung')}</span>
                <br />
                <br />
                <span>4. {t('content.cauHoiTG.thoiGian')}</span>
                <br />
                <br />
                <span>• {t('content.cauHoiTG.thoiGianQuay')}</span>
                <br />
                <br />
                <span>• {t('content.cauHoiTG.tranhSS')}</span>
                <br />
                <br />
                <span>{t('content.cauHoiTG.lienHe')}</span>
            </div>
        </div>
    );
}

export default CauHoiThuongGap;
