import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './History.css';
import { useNavigate } from 'react-router-dom';
import { Nav, NavItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import vitri from '../../assets/images/vitri.png';
import logoX from '../../assets/images/logox.png';

const axios = require('axios').default;

function formateT(params) {
    let result = params < 10 ? '0' + params : params;
    return result;
}

function timerJoin2(params = '') {
    let date = '';
    if (params) {
        date = new Date(Number(params));
    } else {
        date = new Date();
    }
    let years = formateT(date.getFullYear());
    let months = formateT(date.getMonth() + 1);
    let days = formateT(date.getDate());

    let hours = formateT(date.getHours());
    let minutes = formateT(date.getMinutes());
    let seconds = formateT(date.getSeconds());
    return years + '-' + months + '-' + days + ' ' + hours + ':' + minutes + ':' + seconds;
}

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' VNĐ';
}

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function History(args) {
    document.title = args.title;
    let [type_mission, setType] = useState('all');
    let [mission, setMission] = useState([]);
    const [isMatching, setIsMatching] = useState(false);
    const [contentMatching, setContentMatching] = useState('');
    const [activeTab, setActiveTab] = useState('1');
    const [activeTabPane, setActiveTabPane] = useState('1');
    const [isModalOrder, setIsModalOrder] = useState(false);
    const [order, setOrder] = useState();
    const [star, setStar] = useState(5);

    const { t } = useTranslation();

    const toggleModalOrder = () => setIsModalOrder(!isModalOrder);

    useEffect(() => {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/mission/history`, {
                params: {
                    type: type_mission,
                },
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setMission(data.data);
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }, [type_mission]);

    useEffect(() => {
        checkToken();
    }, []);
    let navigate = useNavigate();

    function confirmMission(id, id_mission) {
        setIsMatching(true);

        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/mission/confirm/id`,
                { id: id, id_mission: id_mission },
                {
                    headers,
                },
            )
            .then(async function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    if (data.data.type === 1000) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.daHoanThanh')}`, { theme: 'light' });
                    }

                    if (data.data.type === 2) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.nvKTonTai')}`, { theme: 'light' });
                    }

                    if (data.data.type === 3) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.daLamNv')}`, { theme: 'light' });
                    }

                    if (data.data.type === 4) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.capBacKDu')}`, { theme: 'light' });
                    }
                    if (data.data.type === 5) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.conNvChuaHT')}`, { theme: 'light' });
                    }

                    if (data.data.type === 0) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.soDuKDu')}`, { theme: 'light' });
                    }

                    if (data.data.type === 1) {
                        // setTimeout(() => {
                        //     setContentMatching(`${t('content.confirmOrder.donHangDangGui')}`);
                        // }, 0);

                        // setTimeout(() => {
                        //     setContentMatching(`${t('content.confirmOrder.doiHeThong')}`);
                        // }, 1000);

                        // setTimeout(() => {
                        //     setContentMatching(`${t('content.confirmOrder.doiNguoiBan')}`);
                        // }, 2000);

                        setTimeout(() => {
                            setIsMatching(false);
                        }, 2500);

                        setTimeout(() => {
                            toast.success(`${t('content.confirmOrder.xacNhanDonHangThangCong')}`, { theme: 'light' });
                        }, 3000);

                        setTimeout(() => {
                            navigate(`/order/mission`);
                        }, 4000);
                    }
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }

    if (!Array.isArray(mission)) return false;

    return (
        <div
            className="history-order main-container bg-[#ffffff] flex flex-col items-center justify-center mt-16"
            style={{ borderRadius: '25px' }}
        >
            {isMatching && (
                <div className="matching-animation">
                    <div className="overlay">
                        <div className="textConfirm">{contentMatching}</div>
                        <div className="loader loaderConfirm1"></div>
                        <div className="loader loaderConfirm2"></div>
                        <div className="loader loaderConfirm3"></div>
                    </div>
                </div>
            )}

            {isModalOrder && (
                <Modal isOpen={isModalOrder} toggle={toggleModalOrder} {...args} centered>
                    <div className="modal-home-style">
                        <ModalHeader
                            className="relative"
                            style={{
                                background: `url(${order?.image})`,
                                backgroundSize: 'cover',
                                width: '100%',
                                height: '37rem',
                                backgroundRepeat: 'no-repeat',
                            }}
                        >
                            <div className="absolute top-[5px] right-[5px]" onClick={toggleModalOrder}>
                                <img src={logoX} alt="" width={25} height={25} />
                            </div>
                            <div className="goods-wrap">
                                <div className="goods-content flex flex-col items-start justify-center">
                                    <div className="text-goods w-[100%] flex items-center ml-4 mt-2">
                                        <img src={vitri} alt="" width={32} height={32} />
                                        <div className="goods_name text-white font-bold text-2xl">
                                            {order?.name_mission}
                                        </div>
                                    </div>
                                    <div className="goods w-[100%] flex flex-col px-4">
                                        <div className="goods-cell flex items-center justify-between mt-2">
                                            <span className="text-white font-bold text-2xl">
                                                {t('content.order.soTien')}
                                            </span>
                                            <span className="text-white font-bold text-2xl">
                                                {formatMoney(order?.price)}
                                            </span>
                                        </div>
                                        <div className="goods-cell flex items-center justify-between mt-2">
                                            <span className="text-white font-bold text-2xl">
                                                {t('content.order.tiLe')}
                                            </span>
                                            <span className="text-white font-bold text-2xl">
                                                {formatMoney(order?.receive)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            {order?.description && (
                                <div className="desc-order__style">
                                    <p className="text-3xl font-bold bg-[#3e85ff] mt-3 p-3 rounded-xl text-white text-center uppercase">
                                        {t('content.order.thongTinCT')}
                                    </p>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: order?.description }}
                                        className="text-lg leading-10"
                                    ></div>
                                </div>
                            )}
                            <div className="rate-box flex ml-2 py-8">
                                <span className="font-bold mt-1" style={{ fontSize: '16px', fontFamily: 'Roboto' }}>
                                    {t('content.order.danhGia')}
                                </span>
                                <ul>
                                    <li className={`${star > 0 ? 'light' : ''}`} onClick={() => setStar(1)}></li>
                                    <li className={`${star > 1 ? 'light' : ''}`} onClick={() => setStar(2)}></li>
                                    <li className={`${star > 2 ? 'light' : ''}`} onClick={() => setStar(3)}></li>
                                    <li className={`${star > 3 ? 'light' : ''}`} onClick={() => setStar(4)}></li>
                                    <li className={`${star > 4 ? 'light' : ''}`} onClick={() => setStar(5)}></li>
                                </ul>
                            </div>

                            <div>
                                <textarea
                                    name=""
                                    id=""
                                    cols="45"
                                    rows="5"
                                    placeholder={t('content.order.chiaSe')}
                                    className="text-2xl w-[100%] p-4 border rounded-xl"
                                ></textarea>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div
                                className="mx-2 w-[100%] flex items-center justify-center bg-[#0194F3] h-[4.8rem] rounded-lg text-white text-2xl font-semibold"
                                onClick={() => {
                                    setIsModalOrder(false);
                                    confirmMission(order?.id, order?.id_mission);
                                }}
                            >
                                {t('content.order.gui')}
                            </div>
                        </ModalFooter>
                    </div>
                </Modal>
            )}

            <Nav
                tabs
                className="history-body history-order__style tours-item w-[95%] max-[411px]:text-base text-lg"
                style={{ borderTop: 'none' }}
            >
                <NavItem
                    className={activeTab === '1' ? 'active w-[25%]' : 'w-[25%]'}
                    onClick={() => {
                        setActiveTabPane('1');
                        setActiveTab('1');
                        setType('all');
                    }}
                >
                    {t('content.history.tatCa')}
                </NavItem>
                <NavItem
                    className={activeTab === '2' ? 'active w-[25%]' : 'w-[25%]'}
                    onClick={() => {
                        setActiveTabPane('2');
                        setActiveTab('2');
                        setType('pending_accept');
                    }}
                >
                    {t('content.history.kiemDuyet')}
                </NavItem>
                <NavItem
                    className={activeTab === '3' ? 'active w-[25%]' : 'w-[25%]'}
                    onClick={() => {
                        setActiveTabPane('3');
                        setActiveTab('3');
                        setType('success');
                    }}
                >
                    {t('content.history.thanhCong')}
                </NavItem>
                <NavItem
                    className={activeTab === '4' ? 'active w-[25%]' : 'w-[25%]'}
                    onClick={() => {
                        setActiveTabPane('4');
                        setActiveTab('4');
                        setType('pending_send');
                    }}
                >
                    {t('content.history.choTT')}
                </NavItem>
            </Nav>

            <div className="list-items w-[95%] mb-4">
                {mission.map((item, i) => {
                    return (
                        <div
                            className="w-[100%] mt-4 relative"
                            style={{ background: `url(${item.image})`, borderRadius: '25px', backgroundSize: 'cover' }}
                            key={i}
                        >
                            <div
                                className="background-black__style px-[18px] pb-[36px] pt-[20px] text-white"
                                style={{ borderRadius: '25px' }}
                            >
                                <div
                                    className={`status w-[auto] text-2xl font-bold px-4 py-2 absolute top-0 right-0 ${
                                        item.status === 2 ? 'bg-[#1b67ec] text-white' : 'bg-[#848995] text-[white]'
                                    }`}
                                    style={{ borderTopRightRadius: '25px', borderBottomLeftRadius: '25px' }}
                                >
                                    {item.status === 2
                                        ? `${t('content.history.daHT')}`
                                        : `${
                                              item.status === 0
                                                  ? `${t('content.history.choTT')}`
                                                  : `${t('content.history.choKD')}`
                                          } `}
                                </div>

                                <div
                                    className="header-confirm-order flex items-center justify-between pb-8"
                                    style={{ borderBottom: '1px solid white' }}
                                >
                                    <div className="content-confirm-order">
                                        <span className="text-2xl text-white">{timerJoin2(item.time)}</span>
                                        <br />
                                        <br />
                                        <span className="font-bold text-2xl text-white">{item.name_mission}</span>
                                        <br />
                                        <br />
                                        <span className="text-2xl text-white font-bold">{formatMoney(item.price)}</span>
                                    </div>
                                </div>

                                <div className="flex flex-col">
                                    <div className="flex pt-4 items-center justify-between">
                                        <span className="text-2xl text-white font-bold">
                                            {t('content.history.soTien')}
                                        </span>
                                        <span className="text-2xl text-white font-bold">{formatMoney(item.price)}</span>
                                    </div>

                                    <div className="flex pt-4 items-center justify-between">
                                        <span className="text-2xl text-white font-bold">
                                            {t('content.history.tiLe')}
                                        </span>
                                        <span className="text-2xl text-white font-bold">
                                            {formatMoney(item.receive)}
                                        </span>
                                    </div>
                                </div>

                                {item.status === 0 && (
                                    <div
                                        className="mt-4 w-[100%] flex items-center justify-center bg-[#0194F3] h-[4rem] rounded-lg text-white text-2xl font-semibold"
                                        onClick={() => {
                                            setIsModalOrder(true);
                                            setOrder(item);
                                        }}
                                        // onClick={() => confirmMission(item.id, item.id_mission)}
                                    >
                                        {t('content.order.chiTiet')}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default History;
