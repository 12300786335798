import styles from './My.module.scss';
import classNames from 'classnames/bind';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { MdSecurity } from 'react-icons/md';

const axios = require('axios').default;

const cx = classNames.bind(styles);
const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
function EditPwTransaction({ title }) {
    document.title = title;
    let [passwordOld, setpassword] = useState();
    let [newPassword, setNewPassword] = useState();
    let [RePassword, setrePassword] = useState();
    const [Lock1, setLock1] = useState(false);
    const [Lock2, setLock2] = useState(false);
    const [Lock3, setLock3] = useState(false);

    const { t } = useTranslation();

    const checkLock1 = () => {
        setLock1(!Lock1);
    };

    const checkLock2 = () => {
        setLock2(!Lock2);
    };

    const checkLock3 = () => {
        setLock3(!Lock3);
    };

    useEffect(() => {
        checkToken();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function EditPassword() {
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        if (!passwordOld || !newPassword || !RePassword)
            return toast.warn(`${t('content.login.thieuThongTin')}`, { theme: 'light' });
        if (newPassword !== RePassword)
            return toast.warn(`${t('content.editPwTransaction.mkXacNhan')}`, { theme: 'light' });
        axios
            .put(
                `${SETTINGS.BASE_URL}/api/webapi/edit-password-transaction`,
                { passwordOld, newPassword },
                {
                    headers,
                },
            )
            .then(function (response) {
                let data = response.data;
                if (data.data === 1) {
                    toast.success(`${t('content.editPwTransaction.doiMkThanhCong')}`, { theme: 'light' });
                    setTimeout(() => {
                        window.location.href = '/my';
                    }, 1500);
                }
                if (data.data === 2) toast.error(`${t('content.editPwTransaction.saiMk')}`, { theme: 'light' });
            })
            .catch(function (error) {
                toast.error(t('content.error'), { theme: 'light' });
            });
    }
    return (
        <div className="px-[15px] py-[20px] h-[400px] bg-[white] rounded-3xl mt-24">
            <div className={cx('edit-password', { 'edit-password-style': true })}>
                <div className="form-edit-password p-[20px]">
                    <div className="text-3xl font-medium flex items-center justify-start">
                        <MdSecurity size={24} color="#0194F3" className="mr-4" />
                        {t('content.editPwTransaction.mkRT')}
                    </div>
                    <div className={cx('form-group', { '': true })}>
                        <input
                            type={Lock1 ? 'text' : 'password'}
                            onChange={(e) => setpassword(e.target.value)}
                            className="w-[100%]"
                            placeholder={t('content.editPwTransaction.mkRTC')}
                        />
                        <FontAwesomeIcon
                            onClick={checkLock1}
                            className="svg-pass"
                            icon={Lock1 ? faLockOpen : faLock}
                            style={{ position: 'absolute', right: '20px', top: '30px' }}
                        />
                    </div>
                    <div className={cx('form-group', { '': true })}>
                        <input
                            type={Lock1 ? 'text' : 'password'}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="w-[100%]"
                            placeholder={t('content.editPwTransaction.mkRTM')}
                        />
                        <FontAwesomeIcon
                            onClick={checkLock1}
                            className="svg-pass"
                            icon={Lock1 ? faLockOpen : faLock}
                            style={{ position: 'absolute', right: '20px', top: '30px' }}
                        />
                    </div>
                    <div className={cx('form-group', { '': true })}>
                        <input
                            type={Lock1 ? 'text' : 'password'}
                            onChange={(e) => setrePassword(e.target.value)}
                            className="w-[100%]"
                            placeholder={t('content.editPwTransaction.xacNhanMK')}
                        />
                        <FontAwesomeIcon
                            onClick={checkLock1}
                            className="svg-pass"
                            icon={Lock1 ? faLockOpen : faLock}
                            style={{ position: 'absolute', right: '20px', top: '30px' }}
                        />
                    </div>
                    <div onClick={() => EditPassword()} className={cx('form-group', { 'text-center': true })}>
                        <div className={cx('btn-submit', { 'text-[#fff] text-2xl': true })}>
                            {t('content.editPwTransaction.luuLai')}
                        </div>
                    </div>
                </div>

                <ToastContainer
                    position="top-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </div>
    );
}

export default EditPwTransaction;
