import React, { useEffect, useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import { memo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import logoTraveloka from '../../../../assets/images/sticker.webp';
import { FaRegListAlt } from 'react-icons/fa';

function Header(props) {
    return (
        <div className="fixed top-0 left-1/2 -translate-x-1/2 flex items-center justify-between w-[100%] max-w-[480px] px-4 bg-[#2568DB] z-50">
            <img src="/logo.svg" alt="" width={150} height={50} className="!h-[60px]" />
            <Link to="/order/index">
                <FaRegListAlt color="white" size={24} />
            </Link>
        </div>
    );
}

export default memo(Header);
